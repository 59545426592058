@font-face {
  font-family: "Sunborn";
  src: url("../assets/Sunborn-SansOne.otf");
}

.noise {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  position: fixed;
  pointer-events: none;
}

.noise::before {
  top: -10rem;
  left: -10rem;
  width: calc(100% + 20rem);
  height: calc(100% + 20rem);
  z-index: 2000;
  content: "";
  position: absolute;
  pointer-events: none;
  background-position: 75%;
  background-image: url("../assets/abstract/noise.png");
  pointer-events: none;
  animation: noise 2s steps(2) infinite;
}

@keyframes noise {
  0% {
    transform: translate3d(0, 9rem, 0);
  }

  10% {
    transform: translate3d(-1rem, -4rem, 0);
  }

  20% {
    transform: translate3d(-8rem, 2rem, 0);
  }

  30% {
    transform: translate3d(9rem, -9rem, 0);
  }

  40% {
    transform: translate3d(-2rem, 7rem, 0);
  }

  50% {
    transform: translate3d(-9rem, -4rem, 0);
  }

  60% {
    transform: translate3d(2rem, 6rem, 0);
  }

  70% {
    transform: translate3d(7rem, -8rem, 0);
  }

  80% {
    transform: translate3d(-9rem, 1rem, 0);
  }

  90% {
    transform: translate3d(6rem, -5rem, 0);
  }

  to {
    transform: translate3d(-7rem, 0, 0);
  }
}

.transition {
  z-index: 10000;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  inset: 0%;
  cursor: progress;
}

.transition-background {
  aspect-ratio: 1;
  background-color: hsl(226, 73%, 48%);
  width: 100%;
  position: relative;
}

.welcome {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: hsl(226, 84%, 66%);
  font-weight: 900;

  /* Adjust clamp values to make it more responsive */
  font-size: clamp(3.75rem, 5vw, 8vw);
}

/* From Uiverse.io by vinodjangid07 */
.truck-loader {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 420px) {
  .truck-loader {
    transform: translateY(-55%);
    left: -10rem;
    scale: 0.5;
  }
}

.truckWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  justify-content: flex-end;
  overflow-x: hidden;
}

/* truck upper body */
.truckBody {
  width: 400px;
  height: fit-content;
  margin-bottom: 20px;
  animation: motion 1s linear infinite;
}

.handels-logo {
  position: absolute;
  top: 65px;
  left: 35px;
  width: 200px;
}

/* truck suspension animation*/
@keyframes motion {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* truck's tires */
.truckTires {
  width: 380px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 35px;
  position: absolute;
  bottom: 0;
}

.truckTires svg {
  width: 55px;
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% + 10rem);
  background-color: var(--background-light);
}

.header .bottom-wave {
  background-image: url(../assets/abstract/waves.svg);
  width: 100%;
  height: 16vh;
  position: absolute;
  bottom: -15.9vh;
  left: 0;
  z-index: 10;
  left: 0;
}

.navbar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding-block: 1.25rem;
  z-index: 1000;
  transition: all 0.3s ease;
}

.nav-toggle-button {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 1rem;
  height: 0.75rem;
  cursor: pointer;
}

.hamburger {
  width: 1.25em;
  height: 1.25em;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.nav-toggle-button .hamburger::before,
.nav-toggle-button .hamburger::after {
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0.001deg);
  width: 100%;
  height: 0.166em;
  background: var(--dark);
  transition: var(--animation-primary);
}

.nav-toggle-button .hamburger::after {
  top: 70%;
}

.navbar.nav-active .nav-toggle-button .hamburger::before {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.navbar.nav-active .nav-toggle-button .hamburger::after {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.navbar.nav-active .nav-links {
  top: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transform: translateY(0) rotate(0.001deg);
}

@media screen and (max-width: 1024px) {
  .nav-toggle-button {
    display: flex;
  }

  .navbar {
    flex-direction: column;
  }

  .navbar .container .nav-links {
    position: absolute;
    top: -460%;
    pointer-events: all;
    left: unset;
    right: 0;
    width: 100%;
    z-index: -10;
    flex-direction: column;
    background-color: var(--background-light);
    padding-top: 5rem;
    padding-bottom: calc(var(--gap-padding) * 0.66);
    backdrop-filter: blur(2);
    -webkit-backdrop-filter: blur(1rem);
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    transition: var(--animation-primary);
  }

  .nav-links .link {
    text-align: center;
    padding: 0.5rem 0;
  }

  .nav-links .link .nav-link {
    color: var(--dark);
    font-size: var(--font-size-0);
    font-weight: 500;
  }

  .header .navbar ul li a::after {
    content: "";
    position: absolute;
    height: 0.125rem;
    width: 0;
    bottom: 0;
    left: 0;
    background-color: var(--cherry-red);
    transition: all 0.3s ease;
  }
}

.navbar.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--background-light);
  padding: 0.5rem 0;
  z-index: 50;
  backdrop-filter: blur(2);
  -webkit-backdrop-filter: blur(1rem);
  animation: 0.5s ease-in-out slide-down;
  transition: all 0.3s ease;
}

.navbar.sticky-gone {
  position: fixed;
  top: -5rem;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.5rem 0;
  z-index: 50;
  backdrop-filter: blur(2);
  -webkit-backdrop-filter: blur(0.5rem);
  transition: all 0.3s ease;
}

@keyframes slide-down {
  from {
    opacity: 0;
    top: -4rem;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.navbar .container .logo img {
  width: 10rem;
  cursor: pointer;
}

.navbar ul {
  display: flex;
}

.navbar ul li {
  padding: 1em;
}

.navbar ul li a {
  font-family: "Hind", "Poppins", sans-serif;
  position: relative;
  font-size: var(--font-size--1);
  white-space: nowrap;
}

.navbar ul li a::after {
  content: "";
  position: absolute;
  height: 0.125rem;
  width: 0;
  bottom: 0;
  left: 0;
  background-color: var(--btn-blue);
  transition: all 0.3s ease;
}

.navbar ul li .nav-link:hover::after {
  width: 70%;
}

.navbar ul li .nav-link:active::after {
  width: 30%;
}

/* ==================== WEB INQUIRY ==================== */
.web-inquiry-blur {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.web-inquiry {
  position: fixed;
  box-sizing: content-box;
  background-color: var(--background-light);
  border-radius: 1rem;
  padding: 1rem;
  z-index: 2000;
  width: clamp(320px, 50%, 600px);
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.web-inquiry form {
  position: relative;
  overflow-y: scroll;
  width: 100%;
  height: 88%;
}

.web-inquiry form .address {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  column-gap: 1rem;
}

.web-inquiry form .address .street-address {
  grid-area: 1 / 1 / 2 / 3;
}

.web-inquiry form .address .city {
  grid-area: 2 / 1 / 3 / 3;
}

.web-inquiry form .address .state {
  grid-area: 3 / 1 / 4 / 2;
}

.web-inquiry form .address .postal-code {
  grid-area: 3 / 2 / 4 / 3;
}

.web-inquiry h2 {
  color: var(--background-blue);
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.web-inquiry #inquiry-form #submit-btn {
  display: flex;
  margin: 0 auto;
  margin-top: 1rem;
  font-size: 1.25rem;
}

#submit-btn {
  cursor: not-allowed;
}

#submit-btn:not(:disabled) {
  cursor: pointer;
}

.web-inquiry-open {
  opacity: 1;
  visibility: visible;
  z-index: 1999;
}

.web-inquiry-blur #close {
  position: absolute;
  z-index: 3000;
  box-shadow: 0px 0px 10px -4px;
  width: 3rem;
  height: 3rem;
  top: 1.5rem;
  right: 2rem;
  border-radius: 50%;
  border: 1px solid transparent;
  background-color: #f6f6f6;
  transition: background-color 0.3s;
}

.web-inquiry-blur #close:hover {
  background-color: #d5d5d5;
}

.web-inquiry-blur #close:hover span {
  color: #fff;
}

/* ======================== HERO ======================== */
.hero-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: calc(var(--section-padding) * 1.33);
}

.hero-title-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.hero-title-wrapper h1 {
  color: var(--background-blue);
  font-family: "Sunborn", sans-serif;
  font-weight: 900;
  letter-spacing: 2px;
  font-size: clamp(2.5919rem, 1.2923rem + 6.4978vw, 6.3281rem);
  white-space: nowrap;
}

.hero-title-wrapper .btn-secondary {
  font-size: var(--font-size-1);
  transition: all 0.5s ease;
}

.hero-title-wrapper .btn-secondary:hover {
  transform: translateX(1.5rem);
  background-color: var(--red);
  scale: 105%;
}

.hero-wrapper .truck-wrapper {
  max-width: 1400px;
  min-width: 300px;
  margin-left: -8rem;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 4rem;
}

.truck-wrapper .area {
  position: absolute;
  cursor: pointer;
  left: 6rem;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 1000;
}

.truck-wrapper .truck {
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.truck-wrapper .truck-lights {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  opacity: 0;
  pointer-events: none;
}

.truck-wrapper .truck-lights.active {
  opacity: 1;
}

.truck-wrapper .blob {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  pointer-events: none;
}

/* ========================= PACKAGES ========================== */
section.packages {
  padding-top: calc(var(--section-padding) * 1.5);
  padding-bottom: calc(var(--section-padding) * 2);
  background-color: var(--background-blue);
  position: relative;
  height: 100%;
}

section.packages .section-title {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: var(--vh);
  padding-bottom: var(--title-padding);
  color: var(--background-light);
}

section.packages .package-card-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 2.5rem;
}

.package-card-wrapper .package-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 340px;
  min-width: 300px;
  height: max-content;
  align-items: flex-end;
  padding: 48px 24px 24px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 12px;
}

@media screen and (max-width: 500px) {
  .package-card-wrapper .package-card {
    width: 100%;
  }
}

.package-card .content {
  position: relative;
  gap: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.package-card .content .made-fresh {
  position: absolute;
  top: -1.25rem;
  right: 0;
  width: clamp(100px, 35%, 150px);
}

.package-card .content .custom-img {
  position: absolute;
  top: 0;
  right: 10%;
  scale: 160%;
  width: clamp(100px, 35%, 150px);
}

.package-card .content .custom-img img:first-child {
  position: absolute;
  top: 10%;
  width: clamp(100px, 35%, 150px);
  z-index: -1;
}

.package-card .content .card-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: transparent;
}

.package-card .content .card-header .title,
.package-card .content .card-header .tier {
  font-family: "Poppins", sans-serif;
}

.package-card .content .card-header .tier {
  font-size: var(--font-size-2);
  font-weight: 600;
  line-height: 1;
}

.package-card .content .item-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.package-card .content .package-price h5 {
  font-weight: 600;
}

.package-card .content .package-price .per-guest {
  display: flex;
  gap: 4px;
}

.package-card .content .package-price img {
  width: 14px;
}

.package-card .content .package-price span {
  color: gray;
  font-size: calc(var(--font-size--1) * 0.9);
}

.package-card .content .button {
  position: relative;
  width: 100%;
  text-align: center;
  border-radius: 0.5rem;
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
  padding-bottom: var(--vh);
}

.package-card:last-child .content .button {
  margin-top: 8.7px;
}

.package-card .content .button .btn-secondary {
  width: 100%;
  display: block;
  box-sizing: border-box;
  border-radius: 0.375rem;
  padding-block: 0.75rem;
}

.package-card .content .item-list .package-item {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.package-card .content .item-list .package-item .icon-check {
  position: relative;
  width: 20px;
  height: 20px;
}

.package-card .content .item-list .package-item p {
  font-size: var(--font-size--1);
}

.packages .all-flavors {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(var(--gap-padding) * 2);
}

.packages .all-flavors .btn-primary {
  margin-top: var(--gap-padding);
}

.packages .event-marquee-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8rem;
}

.packages .event-marquee-wrapper .event-marquee-text {
  padding-block: var(--gap-padding);
}

.packages .event-marquee-wrapper .event-marquee-text h5 {
  color: var(--background-light);
  font-weight: 500;
}

.carousel {
  position: relative;
  overflow: hidden;
  width: 90vw;
  max-width: 800px;
  left: 0;
}

.carousel .blur-left {
  width: 60px;
  height: 208px;
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    90deg,
    var(--background-blue),
    hsla(0, 53%, 44%, 0) 90.3%
  );
  left: 0;
}

.carousel .blur-right {
  width: 60px;
  height: 208px;
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    270deg,
    var(--background-blue),
    hsla(0, 0%, 100%, 0) 90.3%
  );
  right: 0;
}

.carousel-items-wrapper {
  animation: carousel-animate 30s linear infinite;
  display: flex;
  will-change: transform;
  gap: 1rem;
  width: 100%;
  margin: 0;
  padding-inline: 0;
}

.carousel-items-wrapper .carousel-item {
  padding: 1rem;
  background: hsl(226, 88%, 35%);
  font-size: var(--font-size-1);
  white-space: nowrap;
  border-radius: 0.5rem;
  color: var(--background-light);
  box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
}

@keyframes carousel-animate {
  to {
    transform: translateX(-150%);
  }
}

/* ================ Meet the Owners Section ================ */
section.owners {
  position: relative;
  background-color: var(--background-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-block: calc(var(--section-padding) * 0.33);
}

section.owners .curve-top {
  background-image: url(../assets/abstract/curve.svg);
  transform: rotateX(180deg);
  width: 100%;
  height: 16vh;
  position: absolute;
  top: -15.5vh;
  left: 0;
  z-index: 10;
}

section.owners .bottom-curve {
  background-image: url(../assets/abstract/curve.svg);
  width: 100%;
  height: 16vh;
  position: absolute;
  bottom: -15.9vh;
  z-index: 10;
  left: 0;
}

section.owners .owners-wrapper {
  display: flex;
  flex-direction: column;
}

section.owners .owners-wrapper .row {
  align-items: center;
}

section.owners .owners-wrapper .row:nth-child(1) .flex-col:nth-child(1) {
  width: 50%;
  padding-right: var(--gap-padding);
}

section.owners .owners-wrapper .row:nth-child(1) .flex-col:nth-child(1) img {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

section.owners .owners-wrapper .row:nth-child(1) .flex-col:nth-child(2) {
  width: 50%;
  padding-left: var(--gap-padding);
}

section.owners .owners-wrapper .row:nth-child(1) .flex-col:nth-child(2) h3 {
  line-height: 1em;
}

section.owners .owners-wrapper .row:nth-child(2) {
  padding-top: var(--gap-padding);
}

section.owners .owners-wrapper .row:nth-child(2) .flex-col:nth-child(1) {
  width: 30%;
  padding-right: var(--gap-padding);
}

section.owners .owners-wrapper .row:nth-child(2) .flex-col:nth-child(2) {
  width: 70%;
  padding-left: var(--gap-padding);
}

section.owners .owners-wrapper .row .flex-col:nth-child(2) h6 {
  font-weight: 500;
  line-height: 1.75em;
  margin-bottom: calc(var(--vh) * 0.66);
}

/* ================== Why Handel's Sections ================== */
section.why-handels {
  position: relative;
  background-color: var(--background-blue);
  padding-top: calc(var(--section-padding) * 1.66);
  padding-bottom: calc(var(--section-padding) * 1.33);
}

section.why-handels {
  color: white;
}

section.why-handels .row {
  align-items: center;
}

section.why-handels .row .flex-col:nth-child(1) {
  width: 30%;
  padding-right: calc(var(--gap-padding) * 0.66);
}

section.why-handels .row .flex-col:nth-child(2) {
  width: 70%;
  border-left: 1px solid white;
  padding-left: var(--gap-padding);
}

section.why-handels .row .flex-col:nth-child(2) p:first-child {
  margin-bottom: var(--vh);
}

@media screen and (max-width: 1024px) {
  .why-handels .row {
    flex-direction: column;
  }

  .why-handels .why-handels-wrapper .row .flex-col:nth-child(1) {
    width: 100%;
    display: flex;
    padding: 0;
  }

  .why-handels .why-handels-wrapper .row .flex-col.why-text {
    width: 100%;
    padding-left: 0;
    border: none;
  }

  .why-handels .why-handels-wrapper .row .flex-col .col-row.col-row-border {
    padding-top: var(--gap-padding);
    border-top: 1px solid white;
    margin-top: var(--vh);
  }
}

/* ====================== Social Sections ====================== */
section.social-posts {
  position: relative;
  padding-block: var(--section-padding);
  background-color: var(--background-light);
}

section.social-posts .top-wave {
  background-image: url(../assets/abstract/waves.svg);
  transform: rotateX(180deg);
  width: 100%;
  height: 16vh;
  position: absolute;
  top: -15.9vh;
  left: 0;
}

section.social-posts .social-posts-wrapper .section-title {
  text-align: center;
  padding-bottom: var(--title-padding);
}

section.social-posts .social-posts-wrapper .section-title h3 {
  font-weight: 800;
  color: var(--dark);
}

section.social-posts .social-posts-wrapper .row {
  justify-content: center;
  gap: var(--gap-padding);
}

section.social-posts .text-link {
  margin-top: var(--gap-padding);
  transition: scale 0.3s;
}

.text-link h5 {
  font-weight: 600;
}

section.social-posts .text-link:hover {
  scale: 102%;
}

section.social-posts .social-card {
  background: #fff;
  padding: 10px;
  border: 3px solid var(--background-blue);
  border-radius: 20px;
}

section.social-posts .social-card .instagram-media {
  border: 0 !important;
}

/* ====================== Book Us Section ====================== */
section.book-us {
  position: relative;
  background-color: var(--background-blue);
  color: white;
  padding-top: calc(var(--section-padding) * 1.33);
  padding-bottom: var(--section-padding);
}

section.book-us .top-wave {
  background-image: url(../assets/abstract/waves.svg);
  width: 100%;
  height: 16vh;
  position: absolute;
  top: 0;
  left: 0;
}

section.book-us .row {
  align-items: center;
}

section.book-us .row .flex-col:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 40%;
  border-right: 1px solid white;
  padding-right: var(--gap-padding);
  text-align: right;
}

section.book-us .row .flex-col:nth-child(2) {
  width: 60%;
  padding-left: var(--gap-padding);
}

section.book-us a.btn-secondary {
  font-size: var(--font-size-0);
}

@media screen and (max-width: 1024px) {
  .book-us .row {
    flex-direction: column;
  }

  .book-us .book-us-wrapper .row .flex-col:nth-child(1) {
    width: 100%;
    flex-direction: row;
    border: none;
  }

  .book-us .book-us-wrapper .row .flex-col:nth-child(1) a {
    margin-left: var(--gap-padding);
  }

  .book-us .book-us-wrapper .row .flex-col:nth-child(2) {
    width: 100%;
    padding-left: 0;
  }

  .book-us .book-us-wrapper .row .flex-col .col-row.col-row-border {
    padding-top: var(--gap-padding);
    border-top: 1px solid white;
    margin-top: var(--vh);
  }
}

.book-us-btn {
  cursor: pointer;
}

/* =========================== Footer =========================== */
footer {
  position: relative;
  background-color: var(--background-blue);
  color: white;
}

footer h4 {
  font-weight: 600;
}

footer h5 {
  font-weight: 400;
}

footer .footer-background {
  box-shadow: 0px -2px 20px 10px rgb(0 0 0 / 20%);
  margin-top: 2rem;
  background-color: var(--background-footer);
  padding-top: calc(var(--section-padding) * 0.33);
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}

footer .footer-wrapper .flex-col.logo {
  width: clamp(250px, 20vw + 1rem, 25vw);
  padding-bottom: calc(var(--gap-padding) * 1.75);
}

footer .row {
  --gap: var(--gap-padding);
  --columns: 5;
  padding-bottom: calc(var(--gap-padding) * 0.75);
  justify-content: space-between;
}

footer .row .flex-col {
  width: calc(
    (99.95% / var(--columns)) -
      (var(--gap) * ((var(--columns) - 1) / var(--columns)))
  );
  padding-right: var(--gap-padding);
  margin-bottom: var(--gap-padding);
}

footer .row .flex-col .col-row.col-row-border {
  padding-top: var(--gap-padding);
  border-top: 1px solid white;
}

@media screen and (max-width: 1024px) {
  footer .row {
    --columns: 2;
    padding-top: calc(var(--section-padding) * 0.5);
    padding-bottom: calc(var(--section-padding) * 1);
  }

  .row .flex-col:nth-child(3) {
    order: 3;
  }

  footer .footer-email-link {
    font-size: var(--font-size--2);
  }
}

@media screen and (max-width: 400px) {
  footer .row {
    --columns: 1;
    padding-top: calc(var(--section-padding) * 0.5);
    padding-bottom: calc(var(--section-padding) * 1);
  }

  .row .flex-col:nth-child(3) {
    order: 3;
  }
}

footer .row .flex-col:first-child img {
  max-width: 200px;
  padding-left: 0;
}

footer .row .row-inner {
  display: flex;
}

footer .stores-list {
  display: flex;
  gap: var(--gap-padding);
}

footer .store-name {
  display: flex;
  flex-direction: column;
}

footer h5,
.footer-link {
  color: white;
  font-weight: 600;
  transition: all 0.3s;
}

.footer-link:hover,
footer h5 .footer-link:hover,
footer .footer-social-link:hover {
  color: var(--footer-link-hover);
  scale: 102%;
}

footer .footer-social-link,
footer .footer-email-link {
  display: flex;
  align-items: center;
  gap: calc(var(--gap-padding) * 0.33);
  color: white;
  transition: all 0.3s;
}

footer .footer-social-link span {
  font-size: var(--font-size-0);
}

footer .banner {
  width: 100%;
  height: 0.75rem;
  background-image: linear-gradient(
    90deg,
    #d72627,
    #d72627 20%,
    #2c3691 20%,
    #2c3691 60%,
    #fff 60%,
    #fff 100%
  );
  background-size: calc(100vw / 6);
  background-position: bottom left;
  background-repeat: repeat-x;
}

@media screen and (max-width: 1024px) {
  .hero-wrapper {
    flex-direction: column;
    padding-bottom: calc(var(--section-padding) * 0.33);
    padding-top: calc(var(--section-padding) * 0.33);
  }

  .hero-title-wrapper {
    align-items: center;
  }

  .hero-wrapper .truck-wrapper {
    padding-bottom: calc(var(--section-padding) * 0.1);
  }

  section.owners {
    padding-block: calc(var(--section-padding) * 0.33);
  }

  section.owners .owners-wrapper .row:nth-child(1) {
    flex-direction: column;
  }

  section.owners .owners-wrapper .row:nth-child(1) .flex-col:nth-child(1) {
    padding-right: 0;
    padding-bottom: var(--gap-padding);
    width: 100%;
  }

  section.owners .owners-wrapper .row:nth-child(1) .flex-col:nth-child(2) {
    padding-left: 0;
    padding-top: var(--gap-padding);
    width: 100%;
  }

  .container__medium {
    width: 80%;
  }

  .container__small {
    width: 80%;
  }

  footer .row {
    padding-top: calc(var(--section-padding) * 0.33);
    padding-bottom: calc(var(--section-padding) * 0.33);
  }
}

@media screen and (max-width: 420px) {
  main .header {
    height: 90vh;
  }

  .packages .event-marquee-wrapper .event-marquee-text {
    text-align: center;
  }

  section.book-us .top-wave,
  section.social-posts .top-wave,
  .header .bottom-wave,
  section.owners .curve-top,
  section.owners .bottom-curve {
    height: 8vh;
  }

  section.owners .curve-top {
    top: -7.9vh;
  }

  section.owners .bottom-curve,
  .header .bottom-wave {
    bottom: -7.9vh;
  }

  section.social-posts .top-wave {
    top: -7.9vh;
  }

  section.book-us {
    padding-top: var(--section-padding);
    padding-bottom: calc(var(--section-padding) * 0.66);
  }

  section.book-us .book-us-wrapper .row .flex-col:nth-child(1) {
    padding-right: 0;
  }

  footer .footer-wrapper .flex-col.logo {
    width: 100%;
    padding-inline: 1rem;
    padding-bottom: 0;
  }

  footer .footer-email-link {
    font-size: inherit;
  }
}
