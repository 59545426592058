@font-face {
  font-family: Sunborn;
  src: url("Sunborn-SansOne.d7a31d86.otf");
}

.noise {
  z-index: 2000;
  pointer-events: none;
  position: fixed;
  inset: 0;
}

.noise:before {
  z-index: 2000;
  content: "";
  pointer-events: none;
  pointer-events: none;
  background-image: url("noise.18c48be6.png");
  background-position: 75%;
  width: calc(100% + 20rem);
  height: calc(100% + 20rem);
  animation: 2s steps(2, end) infinite noise;
  position: absolute;
  top: -10rem;
  left: -10rem;
}

@keyframes noise {
  0% {
    transform: translate3d(0, 9rem, 0);
  }

  10% {
    transform: translate3d(-1rem, -4rem, 0);
  }

  20% {
    transform: translate3d(-8rem, 2rem, 0);
  }

  30% {
    transform: translate3d(9rem, -9rem, 0);
  }

  40% {
    transform: translate3d(-2rem, 7rem, 0);
  }

  50% {
    transform: translate3d(-9rem, -4rem, 0);
  }

  60% {
    transform: translate3d(2rem, 6rem, 0);
  }

  70% {
    transform: translate3d(7rem, -8rem, 0);
  }

  80% {
    transform: translate3d(-9rem, 1rem, 0);
  }

  90% {
    transform: translate3d(6rem, -5rem, 0);
  }

  to {
    transform: translate3d(-7rem, 0, 0);
  }
}

.transition {
  z-index: 10000;
  cursor: progress;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  inset: 0%;
}

.transition-background {
  aspect-ratio: 1;
  background-color: #214bd4;
  width: 100%;
  position: relative;
}

.welcome {
  color: #5f81f1;
  font-size: max(3.75rem, 5vw);
  font-weight: 900;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.truck-loader {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media screen and (width <= 420px) {
  .truck-loader {
    left: -10rem;
    transform: translateY(-55%)scale3d(.5, .5, 1);
  }
}

.truckWrapper {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.truckBody {
  width: 400px;
  height: fit-content;
  margin-bottom: 20px;
  animation: 1s linear infinite motion;
}

.handels-logo {
  width: 200px;
  position: absolute;
  top: 65px;
  left: 35px;
}

@keyframes motion {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(0);
  }
}

.truckTires {
  justify-content: space-between;
  align-items: center;
  width: 380px;
  height: fit-content;
  padding: 0 20px 0 35px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.truckTires svg {
  width: 55px;
}

.header {
  background-color: var(--background-light);
  flex-direction: column;
  justify-content: center;
  height: calc(100% + 10rem);
  display: flex;
  position: relative;
}

.header .bottom-wave {
  z-index: 10;
  background-image: url("waves.ef9c1715.svg");
  width: 100%;
  height: 16vh;
  position: absolute;
  bottom: -15.9vh;
  left: 0;
}

.navbar {
  z-index: 1000;
  width: 100%;
  padding-block: 1.25rem;
  transition: all .3s;
  position: relative;
  top: 0;
  left: 0;
}

.nav-toggle-button {
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 1rem;
  height: .75rem;
  display: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.hamburger {
  z-index: 1;
  width: 1.25em;
  height: 1.25em;
  display: inline-block;
  position: relative;
}

.nav-toggle-button .hamburger:before, .nav-toggle-button .hamburger:after {
  content: "";
  background: var(--dark);
  width: 100%;
  height: .166em;
  transition: var(--animation-primary);
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(.001deg);
}

.nav-toggle-button .hamburger:after {
  top: 70%;
}

.navbar.nav-active .nav-toggle-button .hamburger:before {
  top: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.navbar.nav-active .nav-toggle-button .hamburger:after {
  top: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.navbar.nav-active .nav-links {
  transform: translateY(0)rotate(.001deg);
  box-shadow: 0 4px 12px #0000001a;
  top: 0 !important;
}

@media screen and (width <= 1024px) {
  .nav-toggle-button {
    display: flex;
  }

  .navbar {
    flex-direction: column;
  }

  .navbar .container .nav-links {
    pointer-events: all;
    top: -460%;
    left: unset;
    z-index: -10;
    background-color: var(--background-light);
    width: 100%;
    padding-top: 5rem;
    padding-bottom: calc(var(--gap-padding) * .66);
    -webkit-backdrop-filter: blur(1rem);
    transition: var(--animation-primary);
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    flex-direction: column;
    position: absolute;
    right: 0;
  }

  .nav-links .link {
    text-align: center;
    padding: .5rem 0;
  }

  .nav-links .link .nav-link {
    color: var(--dark);
    font-size: var(--font-size-0);
    font-weight: 500;
  }

  .header .navbar ul li a:after {
    content: "";
    background-color: var(--cherry-red);
    width: 0;
    height: .125rem;
    transition: all .3s;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.navbar.sticky {
  background-color: var(--background-light);
  z-index: 50;
  -webkit-backdrop-filter: blur(1rem);
  width: 100%;
  padding: .5rem 0;
  transition: all .3s;
  animation: .5s ease-in-out slide-down;
  position: fixed;
  top: 0;
  left: 0;
}

.navbar.sticky-gone {
  z-index: 50;
  -webkit-backdrop-filter: blur(.5rem);
  background-color: #ffffffe6;
  width: 100%;
  padding: .5rem 0;
  transition: all .3s;
  position: fixed;
  top: -5rem;
  left: 0;
}

@keyframes slide-down {
  from {
    opacity: 0;
    top: -4rem;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

.navbar .container {
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.navbar .container .logo img {
  cursor: pointer;
  width: 10rem;
}

.navbar ul {
  display: flex;
}

.navbar ul li {
  padding: 1em;
}

.navbar ul li a {
  font-family: Hind, Poppins, sans-serif;
  font-size: var(--font-size--1);
  white-space: nowrap;
  position: relative;
}

.navbar ul li a:after {
  content: "";
  background-color: var(--btn-blue);
  width: 0;
  height: .125rem;
  transition: all .3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.navbar ul li .nav-link:hover:after {
  width: 70%;
}

.navbar ul li .nav-link:active:after {
  width: 30%;
}

.web-inquiry-blur {
  z-index: 0;
  backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  background-color: #0003;
  width: 100vw;
  height: 100vh;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.web-inquiry {
  box-sizing: content-box;
  background-color: var(--background-light);
  z-index: 2000;
  border-radius: 1rem;
  width: clamp(320px, 50%, 600px);
  height: 90%;
  padding: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.web-inquiry form {
  width: 100%;
  height: 88%;
  position: relative;
  overflow-y: scroll;
}

.web-inquiry form .address {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  display: grid;
}

.web-inquiry form .address .street-address {
  grid-area: 1 / 1 / 2 / 3;
}

.web-inquiry form .address .city {
  grid-area: 2 / 1 / 3 / 3;
}

.web-inquiry form .address .state {
  grid-area: 3 / 1 / 4 / 2;
}

.web-inquiry form .address .postal-code {
  grid-area: 3 / 2 / 4 / 3;
}

.web-inquiry h2 {
  color: var(--background-blue);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

.web-inquiry #inquiry-form #submit-btn {
  margin: 1rem auto 0;
  font-size: 1.25rem;
  display: flex;
}

#submit-btn {
  cursor: not-allowed;
}

#submit-btn:not(:disabled) {
  cursor: pointer;
}

.web-inquiry-open {
  opacity: 1;
  visibility: visible;
  z-index: 1999;
}

.web-inquiry-blur #close {
  z-index: 3000;
  background-color: #f6f6f6;
  border: 1px solid #0000;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: background-color .3s;
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  box-shadow: 0 0 10px -4px;
}

.web-inquiry-blur #close:hover {
  background-color: #d5d5d5;
}

.web-inquiry-blur #close:hover span {
  color: #fff;
}

.hero-wrapper {
  box-sizing: border-box;
  height: 100%;
  padding-bottom: calc(var(--section-padding) * 1.33);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.hero-title-wrapper {
  flex-direction: column;
  display: flex;
  position: relative;
}

.hero-title-wrapper h1 {
  color: var(--background-blue);
  letter-spacing: 2px;
  white-space: nowrap;
  font-family: Sunborn, sans-serif;
  font-size: clamp(2.5919rem, 1.2923rem + 6.4978vw, 6.3281rem);
  font-weight: 900;
}

.hero-title-wrapper .btn-secondary {
  font-size: var(--font-size-1);
  transition: all .5s;
}

.hero-title-wrapper .btn-secondary:hover {
  background-color: var(--red);
  transform: translateX(1.5rem)scale3d(1.05, 1.05, 1);
}

.hero-wrapper .truck-wrapper {
  box-sizing: border-box;
  min-width: 300px;
  max-width: 1400px;
  margin-left: -8rem;
  padding-bottom: 4rem;
  position: relative;
}

.truck-wrapper .area {
  cursor: pointer;
  z-index: 1000;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 6rem;
}

.truck-wrapper .truck {
  z-index: 2;
  pointer-events: none;
  position: relative;
}

.truck-wrapper .truck-lights {
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.truck-wrapper .truck-lights.active {
  opacity: 1;
}

.truck-wrapper .blob {
  z-index: 0;
  pointer-events: none;
  width: 100%;
  position: absolute;
  top: 0;
}

section.packages {
  padding-top: calc(var(--section-padding) * 1.5);
  padding-bottom: calc(var(--section-padding) * 2);
  background-color: var(--background-blue);
  height: 100%;
  position: relative;
}

section.packages .section-title {
  text-align: center;
  gap: var(--vh);
  padding-bottom: var(--title-padding);
  color: var(--background-light);
  flex-direction: column;
  display: flex;
}

section.packages .package-card-wrapper {
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 2.5rem;
  display: flex;
  position: relative;
}

.package-card-wrapper .package-card {
  background-color: #fff;
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 340px;
  min-width: 300px;
  height: max-content;
  padding: 48px 24px 24px;
  display: flex;
  position: relative;
  box-shadow: 0 7px 29px #64646f33;
}

@media screen and (width <= 500px) {
  .package-card-wrapper .package-card {
    width: 100%;
  }
}

.package-card .content {
  flex-direction: column;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.package-card .content .made-fresh {
  width: clamp(100px, 35%, 150px);
  position: absolute;
  top: -1.25rem;
  right: 0;
}

.package-card .content .custom-img {
  width: clamp(100px, 35%, 150px);
  position: absolute;
  top: 0;
  right: 10%;
  scale: 160%;
}

.package-card .content .custom-img img:first-child {
  z-index: -1;
  width: clamp(100px, 35%, 150px);
  position: absolute;
  top: 10%;
}

.package-card .content .card-header {
  background-color: #0000;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.package-card .content .card-header .title, .package-card .content .card-header .tier {
  font-family: Poppins, sans-serif;
}

.package-card .content .card-header .tier {
  font-size: var(--font-size-2);
  font-weight: 600;
  line-height: 1;
}

.package-card .content .item-list {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.package-card .content .package-price h5 {
  font-weight: 600;
}

.package-card .content .package-price .per-guest {
  gap: 4px;
  display: flex;
}

.package-card .content .package-price img {
  width: 14px;
}

.package-card .content .package-price span {
  color: gray;
  font-size: calc(var(--font-size--1) * .9);
}

.package-card .content .button {
  text-align: center;
  width: 100%;
  padding-bottom: var(--vh);
  border-radius: .5rem;
  flex: none;
  align-self: stretch;
  align-items: center;
  position: relative;
}

.package-card:last-child .content .button {
  margin-top: 8.7px;
}

.package-card .content .button .btn-secondary {
  box-sizing: border-box;
  border-radius: .375rem;
  width: 100%;
  padding-block: .75rem;
  display: block;
}

.package-card .content .item-list .package-item {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.package-card .content .item-list .package-item .icon-check {
  width: 20px;
  height: 20px;
  position: relative;
}

.package-card .content .item-list .package-item p {
  font-size: var(--font-size--1);
}

.packages .all-flavors {
  padding-top: calc(var(--gap-padding) * 2);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.packages .all-flavors .btn-primary {
  margin-top: var(--gap-padding);
}

.packages .event-marquee-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8rem;
  display: flex;
}

.packages .event-marquee-wrapper .event-marquee-text {
  padding-block: var(--gap-padding);
}

.packages .event-marquee-wrapper .event-marquee-text h5 {
  color: var(--background-light);
  font-weight: 500;
}

.carousel {
  width: 90vw;
  max-width: 800px;
  position: relative;
  left: 0;
  overflow: hidden;
}

.carousel .blur-left {
  z-index: 1;
  background: linear-gradient(90deg, var(--background-blue), #ac353500 90.3%);
  width: 60px;
  height: 208px;
  position: absolute;
  left: 0;
}

.carousel .blur-right {
  z-index: 1;
  background: linear-gradient(270deg, var(--background-blue), #fff0 90.3%);
  width: 60px;
  height: 208px;
  position: absolute;
  right: 0;
}

.carousel-items-wrapper {
  will-change: transform;
  gap: 1rem;
  width: 100%;
  margin: 0;
  padding-inline: 0;
  animation: 30s linear infinite carousel-animate;
  display: flex;
}

.carousel-items-wrapper .carousel-item {
  font-size: var(--font-size-1);
  white-space: nowrap;
  color: var(--background-light);
  box-shadow: 0 .5rem 1rem -.25rem var(--clr-primary-900);
  background: #0b2fa8;
  border-radius: .5rem;
  padding: 1rem;
}

@keyframes carousel-animate {
  to {
    transform: translateX(-150%);
  }
}

section.owners {
  background-color: var(--background-light);
  height: 100%;
  padding-block: calc(var(--section-padding) * .33);
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

section.owners .curve-top {
  z-index: 10;
  background-image: url("curve.8a0461de.svg");
  width: 100%;
  height: 16vh;
  position: absolute;
  top: -15.5vh;
  left: 0;
  transform: rotateX(180deg);
}

section.owners .bottom-curve {
  z-index: 10;
  background-image: url("curve.8a0461de.svg");
  width: 100%;
  height: 16vh;
  position: absolute;
  bottom: -15.9vh;
  left: 0;
}

section.owners .owners-wrapper {
  flex-direction: column;
  display: flex;
}

section.owners .owners-wrapper .row {
  align-items: center;
}

section.owners .owners-wrapper .row:first-child .flex-col:first-child {
  width: 50%;
  padding-right: var(--gap-padding);
}

section.owners .owners-wrapper .row:first-child .flex-col:first-child img {
  border: 1px solid #0000004d;
  border-radius: 2rem;
  box-shadow: 0 4px 12px #0000001a;
}

section.owners .owners-wrapper .row:first-child .flex-col:nth-child(2) {
  width: 50%;
  padding-left: var(--gap-padding);
}

section.owners .owners-wrapper .row:first-child .flex-col:nth-child(2) h3 {
  line-height: 1em;
}

section.owners .owners-wrapper .row:nth-child(2) {
  padding-top: var(--gap-padding);
}

section.owners .owners-wrapper .row:nth-child(2) .flex-col:first-child {
  width: 30%;
  padding-right: var(--gap-padding);
}

section.owners .owners-wrapper .row:nth-child(2) .flex-col:nth-child(2) {
  width: 70%;
  padding-left: var(--gap-padding);
}

section.owners .owners-wrapper .row .flex-col:nth-child(2) h6 {
  margin-bottom: calc(var(--vh) * .66);
  font-weight: 500;
  line-height: 1.75em;
}

section.why-handels {
  background-color: var(--background-blue);
  padding-top: calc(var(--section-padding) * 1.66);
  padding-bottom: calc(var(--section-padding) * 1.33);
  color: #fff;
  position: relative;
}

section.why-handels .row {
  align-items: center;
}

section.why-handels .row .flex-col:first-child {
  width: 30%;
  padding-right: calc(var(--gap-padding) * .66);
}

section.why-handels .row .flex-col:nth-child(2) {
  width: 70%;
  padding-left: var(--gap-padding);
  border-left: 1px solid #fff;
}

section.why-handels .row .flex-col:nth-child(2) p:first-child {
  margin-bottom: var(--vh);
}

@media screen and (width <= 1024px) {
  .why-handels .row {
    flex-direction: column;
  }

  .why-handels .why-handels-wrapper .row .flex-col:first-child {
    width: 100%;
    padding: 0;
    display: flex;
  }

  .why-handels .why-handels-wrapper .row .flex-col.why-text {
    border: none;
    width: 100%;
    padding-left: 0;
  }

  .why-handels .why-handels-wrapper .row .flex-col .col-row.col-row-border {
    padding-top: var(--gap-padding);
    margin-top: var(--vh);
    border-top: 1px solid #fff;
  }
}

section.social-posts {
  padding-block: var(--section-padding);
  background-color: var(--background-light);
  position: relative;
}

section.social-posts .top-wave {
  background-image: url("waves.ef9c1715.svg");
  width: 100%;
  height: 16vh;
  position: absolute;
  top: -15.9vh;
  left: 0;
  transform: rotateX(180deg);
}

section.social-posts .social-posts-wrapper .section-title {
  text-align: center;
  padding-bottom: var(--title-padding);
}

section.social-posts .social-posts-wrapper .section-title h3 {
  color: var(--dark);
  font-weight: 800;
}

section.social-posts .social-posts-wrapper .row {
  justify-content: center;
  gap: var(--gap-padding);
}

section.social-posts .text-link {
  margin-top: var(--gap-padding);
  transition: scale .3s;
}

.text-link h5 {
  font-weight: 600;
}

section.social-posts .text-link:hover {
  scale: 102%;
}

section.social-posts .social-card {
  border: 3px solid var(--background-blue);
  background: #fff;
  border-radius: 20px;
  padding: 10px;
}

section.social-posts .social-card .instagram-media {
  border: 0 !important;
}

section.book-us {
  background-color: var(--background-blue);
  color: #fff;
  padding-top: calc(var(--section-padding) * 1.33);
  padding-bottom: var(--section-padding);
  position: relative;
}

section.book-us .top-wave {
  background-image: url("waves.ef9c1715.svg");
  width: 100%;
  height: 16vh;
  position: absolute;
  top: 0;
  left: 0;
}

section.book-us .row {
  align-items: center;
}

section.book-us .row .flex-col:first-child {
  width: 40%;
  padding-right: var(--gap-padding);
  text-align: right;
  border-right: 1px solid #fff;
  flex-direction: column;
  align-items: end;
  display: flex;
}

section.book-us .row .flex-col:nth-child(2) {
  width: 60%;
  padding-left: var(--gap-padding);
}

section.book-us a.btn-secondary {
  font-size: var(--font-size-0);
}

@media screen and (width <= 1024px) {
  .book-us .row {
    flex-direction: column;
  }

  .book-us .book-us-wrapper .row .flex-col:first-child {
    border: none;
    flex-direction: row;
    width: 100%;
  }

  .book-us .book-us-wrapper .row .flex-col:first-child a {
    margin-left: var(--gap-padding);
  }

  .book-us .book-us-wrapper .row .flex-col:nth-child(2) {
    width: 100%;
    padding-left: 0;
  }

  .book-us .book-us-wrapper .row .flex-col .col-row.col-row-border {
    padding-top: var(--gap-padding);
    margin-top: var(--vh);
    border-top: 1px solid #fff;
  }
}

.book-us-btn {
  cursor: pointer;
}

footer {
  background-color: var(--background-blue);
  color: #fff;
  position: relative;
}

footer h4 {
  font-weight: 600;
}

footer h5 {
  font-weight: 400;
}

footer .footer-background {
  background-color: var(--background-footer);
  padding-top: calc(var(--section-padding) * .33);
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  margin-top: 2rem;
  box-shadow: 0 -2px 20px 10px #0003;
}

footer .footer-wrapper .flex-col.logo {
  width: clamp(250px, 20vw + 1rem, 25vw);
  padding-bottom: calc(var(--gap-padding) * 1.75);
}

footer .row {
  --gap: var(--gap-padding);
  --columns: 5;
  padding-bottom: calc(var(--gap-padding) * .75);
  justify-content: space-between;
}

footer .row .flex-col {
  width: calc((99.95% / var(--columns))  - (var(--gap) * ((var(--columns)  - 1) / var(--columns))));
  padding-right: var(--gap-padding);
  margin-bottom: var(--gap-padding);
}

footer .row .flex-col .col-row.col-row-border {
  padding-top: var(--gap-padding);
  border-top: 1px solid #fff;
}

@media screen and (width <= 1024px) {
  footer .row {
    --columns: 2;
    padding-top: calc(var(--section-padding) * .5);
    padding-bottom: calc(var(--section-padding) * 1);
  }

  .row .flex-col:nth-child(3) {
    order: 3;
  }

  footer .footer-email-link {
    font-size: var(--font-size--2);
  }
}

@media screen and (width <= 400px) {
  footer .row {
    --columns: 1;
    padding-top: calc(var(--section-padding) * .5);
    padding-bottom: calc(var(--section-padding) * 1);
  }

  .row .flex-col:nth-child(3) {
    order: 3;
  }
}

footer .row .flex-col:first-child img {
  max-width: 200px;
  padding-left: 0;
}

footer .row .row-inner {
  display: flex;
}

footer .stores-list {
  gap: var(--gap-padding);
  display: flex;
}

footer .store-name {
  flex-direction: column;
  display: flex;
}

footer h5, .footer-link {
  color: #fff;
  font-weight: 600;
  transition: all .3s;
}

.footer-link:hover, footer h5 .footer-link:hover, footer .footer-social-link:hover {
  color: var(--footer-link-hover);
  scale: 102%;
}

footer .footer-social-link, footer .footer-email-link {
  align-items: center;
  gap: calc(var(--gap-padding) * .33);
  color: #fff;
  transition: all .3s;
  display: flex;
}

footer .footer-social-link span {
  font-size: var(--font-size-0);
}

footer .banner {
  background-image: linear-gradient(90deg, #d72627, #d72627 20%, #2c3691 20% 60%, #fff 60% 100%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 16.6667vw;
  width: 100%;
  height: .75rem;
}

@media screen and (width <= 1024px) {
  .hero-wrapper {
    padding-bottom: calc(var(--section-padding) * .33);
    padding-top: calc(var(--section-padding) * .33);
    flex-direction: column;
  }

  .hero-title-wrapper {
    align-items: center;
  }

  .hero-wrapper .truck-wrapper {
    padding-bottom: calc(var(--section-padding) * .1);
  }

  section.owners {
    padding-block: calc(var(--section-padding) * .33);
  }

  section.owners .owners-wrapper .row:first-child {
    flex-direction: column;
  }

  section.owners .owners-wrapper .row:first-child .flex-col:first-child {
    padding-right: 0;
    padding-bottom: var(--gap-padding);
    width: 100%;
  }

  section.owners .owners-wrapper .row:first-child .flex-col:nth-child(2) {
    padding-left: 0;
    padding-top: var(--gap-padding);
    width: 100%;
  }

  .container__medium, .container__small {
    width: 80%;
  }

  footer .row {
    padding-top: calc(var(--section-padding) * .33);
    padding-bottom: calc(var(--section-padding) * .33);
  }
}

@media screen and (width <= 420px) {
  main .header {
    height: 90vh;
  }

  .packages .event-marquee-wrapper .event-marquee-text {
    text-align: center;
  }

  section.book-us .top-wave, section.social-posts .top-wave, .header .bottom-wave, section.owners .curve-top, section.owners .bottom-curve {
    height: 8vh;
  }

  section.owners .curve-top {
    top: -7.9vh;
  }

  section.owners .bottom-curve, .header .bottom-wave {
    bottom: -7.9vh;
  }

  section.social-posts .top-wave {
    top: -7.9vh;
  }

  section.book-us {
    padding-top: var(--section-padding);
    padding-bottom: calc(var(--section-padding) * .66);
  }

  section.book-us .book-us-wrapper .row .flex-col:first-child {
    padding-right: 0;
  }

  footer .footer-wrapper .flex-col.logo {
    padding-inline: 1rem;
    width: 100%;
    padding-bottom: 0;
  }

  footer .footer-email-link {
    font-size: inherit;
  }
}
/*# sourceMappingURL=index.71be3ebd.css.map */
